<template>
  <campaign-form :entity="campaign" :is-loading="isLoading" @update="loadEntity"></campaign-form>
</template>

<script>
import { mapActions } from 'vuex';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import CampaignForm from '@/components/organisms/modules/socialAudience/campaign/form/CampaignForm';
import metaInfo from '@/mixins/common/metaInfo';
import { list } from '@/router/private/modules/socialAudience/demand/campaign/list';

import { getCampaignById } from '@/services/modules/socialAudience/campaign';
import { getInsertionByCampaign } from '@/services/modules/socialAudience/insertion/getInsertionByCampaign';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';

export default {
  name: 'CampaignEdit',
  components: {
    CampaignForm,
  },
  mixins: [metaInfo],
  props: {
    campaignId: { type: String, default: () => null },
    campaignToEdit: { type: Object, default: () => null },
  },
  data: () => ({
    campaign: null,
    isLoading: true,
  }),
  async created() {
    await this.loadEntity();
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    async loadEntity() {
      try {
        if (!this.campaignId) return;
        this.isLoading = true;

        const [{ data: campaign }, { data: insertionList }] = await Promise.all([
          getCampaignById(this.campaignId),
          getInsertionByCampaign(this.campaignId),
        ]);

        if (campaign.isCompleted()) {
          this.createToast(Toast.warning('Warning', `You can't edit, the campaign has been completed`));
        }

        const insertion = insertionList?.[0];
        if (insertion) {
          campaign.setInsertion(insertion);
          this.campaign = campaign;
        } else {
          this.campaign = this.campaignToEdit || campaign;
        }

        this.updateTitle();
        this.isLoading = false;
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          this.createToast(Toast.error(`Can't get campaign`, error.message));
          await this.$router.push({ name: list.name });
        } else {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
