<template>
  <campaign-form :entity="campaign" :is-loading="isLoading" @update="redirectToEditView"></campaign-form>
</template>

<script>
import CampaignForm from '@/components/organisms/modules/socialAudience/campaign/form/CampaignForm';
import metaInfo from '@/mixins/common/metaInfo';
import basicInfoRoute from '@/router/private/modules/socialAudience/demand/campaign/editForm/basicInfo';
import { creative } from '@/router/private/modules/socialAudience/demand/campaign/editForm/creative';

import Campaign from '@/entities/socialAudience/Campaign';

export default {
  name: 'CampaignCreate',
  components: {
    CampaignForm,
  },
  mixins: [metaInfo],
  props: {},
  data: () => ({
    campaign: new Campaign(),
    isLoading: false,
  }),
  methods: {
    async redirectToEditView({ entity, nextStep }) {
      this.$router.push({
        name: nextStep ? creative.name : basicInfoRoute.name,
        params: {
          campaignId: entity.id,
          campaignToEdit: entity,
        },
      });
    },
  },
};
</script>
