<template>
  <div>
    <meta-actions-header class="mb-12" :is-loading="isLoading">
      <template v-if="!isLoading" #header>
        <breadcrumb class="flex flex-1 text-gray-700" :class="{ 'text-orange-500': !campaign.id }">
          <span v-if="campaign.id"
            >:
            <span v-if="campaign" class="text-orange-500">
              {{ campaign.name }}
              <status-pill :status="campaign.status.value" :color="campaign.status.color" />
            </span>
          </span>
        </breadcrumb>
      </template>
      <template v-if="campaignCreated" #actions>
        <sun-button
          v-if="campaignCanBeActived"
          id="active-button"
          class="custom-p-1 text-xs bg-green-500 hover:bg-green-800"
          color="green"
          :disabled="areActionsDisabled"
          variant="pill"
          @click="clickFlowActions(flowActions.active)"
        >
          <div class="flex items-center">
            <online-svg class="-ml-1 mr-2" />
            <span v-if="campaignStatus.draft">Put online</span>
            <span v-else-if="campaignStatus.paused">Active</span>
            <span v-else-if="campaignStatus.pending && userAdminOrAdOps">Approve</span>
          </div>
        </sun-button>
        <sun-button
          v-if="campaignStatus.running"
          id="pause-button"
          class="custom-p-1 text-xs bg-yellow-500 hover:bg-yellow-800 ml-2"
          color="yellow"
          :disabled="areActionsDisabled"
          variant="pill"
          @click="clickFlowActions(flowActions.pause)"
        >
          <div class="flex items-center">
            <pause-svg class="-ml-1 mr-2" />
            <span>Pause</span>
          </div>
        </sun-button>
        <sun-button
          v-if="campaignStatus.running || campaignStatus.paused"
          id="finish-button"
          class="custom-p-1 text-xs bg-red-500 hover:bg-red-800 ml-2"
          color="red"
          :disabled="areActionsDisabled"
          variant="pill"
          @click="clickFlowActions(flowActions.finish)"
        >
          <div class="flex items-center">
            <stop-svg class="-ml-1 mr-2" />
            <span>Finish</span>
          </div>
        </sun-button>
      </template>
    </meta-actions-header>

    <tabs
      v-if="!isLoading"
      :campaign="campaign"
      :insertion-id="campaign.insertionId"
      :read-only="campaignCompleted"
      :tabs-data="tabs"
      @locked="onTabLocked"
      @dirty="onTabDirty"
      @update="onUpdate"
      @edit-form="isChangingForm"
    />

    <card v-if="isLoading">
      <card-form-loading></card-form-loading>
      <card-form-loading class="mt-10"></card-form-loading>
      <card-form-loading class="mt-10"></card-form-loading>
      <card-form-loading class="mt-10"></card-form-loading>
    </card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { ACTIVE_CLIENT, ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import { USER } from '@/store/modules/auth/keys';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { CAPPING_RESOURCE } from '@/services/keys';
import metaInfo from '@/mixins/common/metaInfo';
import { Toast } from '@/model/shared/Toast';
import { ROLES } from '@/model/shared/roles';

import MetaActionsHeader from '@/components/organisms/shared/MetaActionsHeader';
import CardFormLoading from '@/components/atoms/CardFormLoading';
import Card from '@/components/atoms/Card/Card';
import StatusPill from '@/components/atoms/StatusPill';

import { SocialAudienceApi } from '@/services/api/SocialAudienceApi';
import { putOnlineCampaign, pauseCampaign, finishCampaign } from '@/services/modules/socialAudience/campaign';
import CONFIG from '@/components/organisms/modules/socialAudience/campaign/form/config';
import Tabs from '@/components/atoms/Tabs';

import Campaign from '@/entities/socialAudience/Campaign';
import Breadcrumb from '@/components/atoms/Breadcrumb';
import OnlineSvg from '@/components/icons/OnlineSvg';
import PauseSvg from '@/components/icons/PauseSvg';
import StopSvg from '@/components/icons/StopSvg';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';

export default {
  name: 'CampaignForm',
  components: {
    Breadcrumb,
    MetaActionsHeader,
    Tabs,
    CardFormLoading,
    Card,
    StatusPill,
    OnlineSvg,
    PauseSvg,
    StopSvg,
  },
  mixins: [metaInfo],
  props: {
    isLoading: { type: Boolean, default: () => false },
    entity: { type: Campaign, default: () => null },
  },
  data: () => ({
    hasError: false,
    campaign: new Campaign(),
    formErrors: {
      name: '',
      advertiser: null,
      state: null,
      priority: 1,
    },
    currentApi: new SocialAudienceApi(),
    resource: CAPPING_RESOURCE,
    flowActions: CONFIG.flowActions,
    changingForm: false,
    isLoadingInternal: false,
  }),
  computed: {
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
      activeContext: ACTIVE_CONTEXT,
      user: USER,
    }),
    tabs() {
      return this.entity?.id ? CONFIG.tabs.edit : CONFIG.tabs.create;
    },
    campaignCreated() {
      return this.campaign?.id;
    },
    campaignStatus() {
      const { value: status } = this.campaign.status;
      return {
        draft: status === 'DRAFT',
        pending: status === 'PENDING',
        paused: status === 'PAUSED',
        running: status === 'RUNNING',
      };
    },
    campaignCanBeApproved() {
      return this.campaignStatus.pending && this.userAdminOrAdOps;
    },
    campaignCanBeActived() {
      return this.campaignStatus.draft || this.campaignStatus.paused || this.campaignCanBeApproved;
    },
    userAdminOrAdOps() {
      const userRole = this.user?.contextRoles?.find(
        contextRole => contextRole.context === this.activeContext.id
      )?.role;

      return [ROLES.ADMIN.id, ROLES.AD_OPS.id].includes(userRole);
    },
    campaignCompleted() {
      return this.campaign.isCompleted();
    },
    areActionsDisabled() {
      return this.isLoadingInternal || this.changingForm;
    },
  },
  watch: {
    // Avoid user can access to the edit form with empty tab redirecting him
    $route(newRoute) {
      if (!newRoute.params?.campaignId) {
        this.redirectToFirstTab();
      }
    },
    entity: {
      immediate: true,
      handler(entity) {
        this.campaign = entity;
      },
    },
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    metaTitleReplacement() {
      return { campaign: this.campaign?.name } || {};
    },
    redirectToFirstTab() {
      if (this.$route.name !== this.tabs[0].route.name) {
        this.$router.push(this.tabs[0].route);
      }
    },
    onUpdate(entity) {
      this.$emit('update', entity);
    },
    onTabLocked(tab) {
      this.createToast(
        Toast.warning('Locked!', `To view the ${tab.name} section. You must complete the campaign basic info`)
      );
    },
    onTabDirty() {
      this.createToast(Toast.warning('Warning!', `You have unsaved work`));
    },
    async clickFlowActions(action) {
      this.isLoadingInternal = true;
      try {
        switch (action.id) {
          case this.flowActions.active.id:
            await putOnlineCampaign(this.campaign.id);
            break;
          case this.flowActions.pause.id:
            await pauseCampaign(this.campaign.id);
            break;
          case this.flowActions.finish.id:
            await finishCampaign(this.campaign.id);
            break;
        }
        this.updateStatus(action);
        this.createToast(
          Toast.success(
            `Campaign ${action.toastText}`,
            `Campaign ${this.campaign.name} was ${action.toastText} successfully.`
          )
        );
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          this.createToast(Toast.error(`Campaign not ${action.toastText}`, error.message));
        }
      } finally {
        this.isLoadingInternal = false;
      }
    },
    updateStatus(action) {
      this.campaign.status = action.statusTransiction[this.campaign.status.id];
      if (this.campaignCanBeApproved) this.updateStatus(action);
    },
    isChangingForm(isChanged) {
      this.changingForm = isChanged;
    },
  },
};
</script>
