var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("campaign-form", {
    attrs: { entity: _vm.campaign, "is-loading": _vm.isLoading },
    on: { update: _vm.redirectToEditView },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }