var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("meta-actions-header", {
        staticClass: "mb-12",
        attrs: { "is-loading": _vm.isLoading },
        scopedSlots: _vm._u(
          [
            !_vm.isLoading
              ? {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "breadcrumb",
                        {
                          staticClass: "flex flex-1 text-gray-700",
                          class: { "text-orange-500": !_vm.campaign.id },
                        },
                        [
                          _vm.campaign.id
                            ? _c("span", [
                                _vm._v(": "),
                                _vm.campaign
                                  ? _c(
                                      "span",
                                      { staticClass: "text-orange-500" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.campaign.name) + " "
                                        ),
                                        _c("status-pill", {
                                          attrs: {
                                            status: _vm.campaign.status.value,
                                            color: _vm.campaign.status.color,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
            _vm.campaignCreated
              ? {
                  key: "actions",
                  fn: function () {
                    return [
                      _vm.campaignCanBeActived
                        ? _c(
                            "sun-button",
                            {
                              staticClass:
                                "custom-p-1 text-xs bg-green-500 hover:bg-green-800",
                              attrs: {
                                id: "active-button",
                                color: "green",
                                disabled: _vm.areActionsDisabled,
                                variant: "pill",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.clickFlowActions(
                                    _vm.flowActions.active
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex items-center" },
                                [
                                  _c("online-svg", {
                                    staticClass: "-ml-1 mr-2",
                                  }),
                                  _vm.campaignStatus.draft
                                    ? _c("span", [_vm._v("Put online")])
                                    : _vm.campaignStatus.paused
                                    ? _c("span", [_vm._v("Active")])
                                    : _vm.campaignStatus.pending &&
                                      _vm.userAdminOrAdOps
                                    ? _c("span", [_vm._v("Approve")])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.campaignStatus.running
                        ? _c(
                            "sun-button",
                            {
                              staticClass:
                                "custom-p-1 text-xs bg-yellow-500 hover:bg-yellow-800 ml-2",
                              attrs: {
                                id: "pause-button",
                                color: "yellow",
                                disabled: _vm.areActionsDisabled,
                                variant: "pill",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.clickFlowActions(
                                    _vm.flowActions.pause
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex items-center" },
                                [
                                  _c("pause-svg", {
                                    staticClass: "-ml-1 mr-2",
                                  }),
                                  _c("span", [_vm._v("Pause")]),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.campaignStatus.running || _vm.campaignStatus.paused
                        ? _c(
                            "sun-button",
                            {
                              staticClass:
                                "custom-p-1 text-xs bg-red-500 hover:bg-red-800 ml-2",
                              attrs: {
                                id: "finish-button",
                                color: "red",
                                disabled: _vm.areActionsDisabled,
                                variant: "pill",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.clickFlowActions(
                                    _vm.flowActions.finish
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex items-center" },
                                [
                                  _c("stop-svg", { staticClass: "-ml-1 mr-2" }),
                                  _c("span", [_vm._v("Finish")]),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      !_vm.isLoading
        ? _c("tabs", {
            attrs: {
              campaign: _vm.campaign,
              "insertion-id": _vm.campaign.insertionId,
              "read-only": _vm.campaignCompleted,
              "tabs-data": _vm.tabs,
            },
            on: {
              locked: _vm.onTabLocked,
              dirty: _vm.onTabDirty,
              update: _vm.onUpdate,
              "edit-form": _vm.isChangingForm,
            },
          })
        : _vm._e(),
      _vm.isLoading
        ? _c(
            "card",
            [
              _c("card-form-loading"),
              _c("card-form-loading", { staticClass: "mt-10" }),
              _c("card-form-loading", { staticClass: "mt-10" }),
              _c("card-form-loading", { staticClass: "mt-10" }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }